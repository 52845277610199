.modal-title {
  font-size: map-get($font-sizes, 7);
  color: var(--#{$prefix}secondary-color);
}

@include media-breakpoint-up(md) {
  .modal-md {
    --#{$prefix}modal-width: 768px;
  }
}
