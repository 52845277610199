#appCalendar {
  height: calc(100vh - 12.4rem) !important;
}
.fc {
  --fc-page-bg-color: transparent;
  --fc-event-text-color: var(--#{$prefix}body-color);

  .fc-button {
    .fc-icon {
      line-height: 1rem;
      font-size: 1.2em;
    }
  }
  .fc-scrollgrid {
    border-color: var(--#{$prefix}calendar-border-color);
  }
  .fc-timegrid-body {
    background-color: var(--#{$prefix}calendar-bg);
  }

  .fc-timegrid-axis-frame {
    background-color: var(--#{$prefix}calendar-bg);
  }

  &.fc-theme-standard {
    a:not([href]) {
      color: inherit;
    }
    .fc-list,
    td,
    th {
      border: 1px solid var(--#{$prefix}calendar-border-color);
    }

    .fc-daygrid-day {
      background-color: var(--#{$prefix}calendar-bg);
      &.fc-day-other {
        opacity: 0.4;
        .fc-daygrid-day-top {
          opacity: 1;
        }
      }
      &.fc-day-fri,
      &.fc-day-sat {
        color: var(--#{$prefix}warning-dark) !important;
      }
    }
  }
  .fc-col-header {
    background-color: var(--#{$prefix}body-highlight-bg);
    th {
      border-bottom-width: 1px;
      font-weight: $font-weight-semibold;
      color: var(--#{$prefix}tertiary-color);
    }
  }
  .fc-col-header-cell-cushion {
    text-decoration: none !important;
    padding-top: 12px;
    padding-bottom: 8px;
  }

  // week view
  .fc-timeGridWeek-view {
    @include media-breakpoint-up(lg){
      .fc-col-header-cell-cushion {
        width: 70px;
      }
    }
    .fc-timegrid-divider {
      display: none;
    }
    .fc-col-header-cell {
      &.fc-day-today {
        .fc-col-header-cell-cushion {
          position: relative;
          color: var(--#{$prefix}primary);
          @include media-breakpoint-up(xl) {
            color: var(--#{$prefix}body-color);
            &::after {
              content: '';
              position: absolute;
              left: -8px;
              top: 43%;
              width: 0;
              height: 0;
              border: 4px solid var(--#{$prefix}primary);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .fc-daygrid-day-frame {
    border: 2px solid transparent;
    padding: 2px !important;
    transition: $transition-base;
    &:active {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
  }
  .fc-daygrid-day-top {
    justify-content: flex-end;
    margin-bottom: 0.25rem;
  }
  .fc-daygrid-day-number {
    width: 1.875rem;
    height: 1.875rem;
    // background-color: var(--#{$prefix}body-highlight-bg);
    text-align: center;
    text-decoration: none !important;
    border-radius: 50%;
    line-height: 1.875rem;
    padding: 0 !important;
    transition: $transition-base;
    @include hover-focus {
      // background-color: var(--#{$prefix}secondary-bg);
    }
  }
  .fc-daygrid-bg-harness {
    top: -2px;
  }
  // Events
  .fc-daygrid-event {
    border-radius: $border-radius !important;
    margin-top: 0;
    margin-bottom: 0.25rem !important;
    padding: 0.5rem 0.5rem !important;
    font-size: map-get($font-sizes, 10);
    border: 1px solid var(--#{$prefix}border-color) !important;
  }
  .fc-h-event,
  .fc-daygrid-event {
    background-color: var(--#{$prefix}emphasis-bg) !important;
    .fc-event-main {
      padding-left: 15px;

      &::after {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        margin: 0 4px;
        box-sizing: content-box;
        width: 0;
        height: 0;
        border: 4px solid var(--#{$prefix}primary);
        border-radius: 4px;
      }
    }
    .fc-event-time,
    .fc-event-title {
      font-weight: $font-weight-semibold !important;
      line-height: 1;
      display: flex;
      align-items: center;
    }
  }
  .fc-event{
    &:focus{
      box-shadow: none;
      &::after{
        border-radius: 0.375rem;
      }
    }
  }
  .fc-event-title {
    font-weight: normal !important;
  }
  .fc-daygrid-event-dot {
    border-color: var(--#{$prefix}border-color) !important;
  }
  .fc-daygrid-dot-event {
    align-items: baseline !important;
  }
  .fc-day-today:not(.fc-popover) {
    // background-color: transparent !important;
    &.fc-timegrid-col {
      background-color: transparent !important;
    }

    .fc-daygrid-day-number {
      background-color: var(--#{$prefix}primary) !important;
      color: #fff;
      @include hover-focus {
        background-color: var(--#{$prefix}primary-dark) !important;
      }
    }
  }
  &.fc-direction-rtl,
  &.fc-direction-ltr {
    .fc-daygrid-event {
      &.fc-event-start,
      &.fc-event-end {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .fc-popover {
    border-color: var(--#{$prefix}border-color-translucent);
    box-shadow: $box-shadow;
    background-color: var(--#{$prefix}emphasis-bg);
    z-index: 1000;
    border-radius: var(--#{$prefix}border-radius);
    .fc-popover-title {
      font-family: var(--#{$prefix}font-sans-serif);
    }
    .fc-daygrid-event {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 6px !important;
    }
  }
  .fc-popover-header {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    font-size: 0.875rem;
    font-weight: $font-weight-semibold;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    background: var(--#{$prefix}emphasis-bg);
  }
  .fc-more-popover{
    .fc-popover-body{
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
    }
  }

  .fc-daygrid-more-link {
    display: block;
    text-align: center;
    color: var(--#{$prefix}quaternary-color) !important;
    font-size: map-get($font-sizes, 10);
    @include hover-focus {
      text-decoration: none;
      color: var(--#{$prefix}tertiary-color) !important;
    }
  }
  .fc-daygrid-dot-event {
    color: var(--#{$prefix}quaternary-color) !important;
    @include hover-focus {
      background-color: var(--#{$prefix}body-highlight-bg) !important;
    }
  }
  .fc-day:not(.fc-popover) .fc-daygrid-dot-event {
    display: flex;
    justify-content: center;
  }

  // Event List View
  .fc-list-event {
    &:hover td {
      background-color: unset;
    }
  }
  .fc-dayGridMonth-view {
    .fc-event-time {
      display: none;
    }
  }
  .fc-timeGridDay-view .fc-scrollgrid-sync-inner {
    text-align: left;
  }
  .fc-timeGridDay-view,
  .fc-timeGridWeek-view {
    .fc-daygrid-day-events {
      margin-bottom: 0;
    }
    .fc-v-event .fc-event-main {
      padding-left: 1rem;
      color: var(--#{$prefix}tertiary-color);
      &:after {
        content: '';
        position: absolute;
        height: 0.625rem;
        width: 0.625rem;
        border-radius: 50%;
        background-color: var(--#{$prefix}quaternary-bg);
        top: 0.3125rem;
        left: 0;
      }
    }
    .fc-timegrid-event {
      padding: 0.5rem;
      background-color: transparent;
      border: 0;
      border-radius: $border-radius-lg;
      @include hover-focus {
        background-color: var(--#{$prefix}secondary-bg);
      }
    }
    .fc-timegrid-slot {
      height: 2rem;
    }
    .fc-timegrid-slot-lane {
      cursor: pointer;
      &:active {
        background-color: var(--#{$prefix}body-highlight-bg);
      }
    }
    .fc-timegrid-col {
      padding: 0.375rem !important;
    }
  }
  .fc-list {
    .fc-list-day-cushion {
      padding: 0.5rem 1.25rem;
      background-color: var(--fc-button-list-day-cushion);
    }
    .fc-list-day:not(:first-child) {
      .fc-list-day-cushion {
        margin-top: map-get($spacers, 4);
      }
    }
    .fc-list-event-time {
      padding-left: 1.25rem;
    }
    .fc-list-event-title {
      padding-right: 1.25rem;
    }
  }
  .fc-list-empty {
    background-color: var(--#{$prefix}body-highlight-bg);
  }
  .fc-list-event-dot {
    border-color: var(--#{$prefix}border-color);
  }

  @each $color, $value in $theme-colors {
    .fc-timegrid {
      .text-#{$color} {
        .fc-event-main:after {
          background-color: $value !important;
        }
      }
    }
    .text-#{$color} {
      // border: 0 !important;
      .fc-event-main:after {
        background-color: $value !important;
      }
      &.fc-v-event {
        border: 1px solid var(--#{$prefix}border-color) !important;
        background-color: var(--#{$prefix}body-highlight-bg) !important;
      }
    }

    .text-#{$color} {
      &.fc-daygrid-event {
        .fc-event-main {
          color: var(--#{$prefix}#{$color}) !important;
          &::after {
            border: 4px solid var(--#{$prefix}#{$color});
          }
        }
        .fc-daygrid-event-dot {
          border-color: var(--#{$prefix}#{$color}) !important;
        }
      }
    }
  }
}
// [data-fc-view]:not(.active) .icon-check {
//   opacity: 0;
// }
.timeline {
  li {
    position: relative;
    padding-left: 1.5rem;
    &:after {
      position: absolute;
      content: '';
      height: 0.625rem;
      width: 0.625rem;
      border-radius: 50%;
      background: var(--#{$prefix}secondary-bg);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:not(:last-child):before {
      position: absolute;
      content: '';
      height: 100%;
      width: 1px;
      background-color: var(--#{$prefix}secondary-bg);
      top: 50%;
      left: 0.3125rem;
    }
  }
}
.windows {
  &.chrome {
    .fc-scroller {
      overflow: hidden auto !important;
      @include chrome-scrollbar;
    }
  }
  &.firefox {
    .fc-scroller {
      overflow: hidden auto !important;
      @include firefox-scrollbar;
    }
  }
}

.firefox {
  .fc {
    table{
      border-collapse: unset;
    }
  }
}


@include media-breakpoint-up(md) {
  .fc {
    .fc-daygrid-day-frame {
      padding: 0.375rem !important;
    }
    .fc-daygrid-more-link {
      margin-left: 0.625rem;
      text-align: left;
      font-size: map-get($font-sizes, 9);
    }
    .fc-daygrid-event {
      font-size: map-get($font-sizes, 9);
    }
  }
}
.ie {
  .fc-daygrid-event {
    overflow: hidden;
  }
}
// .safari {
//   .fc-dayGridMonth-view {
//     .fc-daygrid-day {
//       position: relative;
//       .fc-daygrid-day-frame {
//         position: absolute;
//         left: 0;
//         top: 0;
//         right: 0;
//         bottom: 0;
//       }
//     }
//   }
// }

.fc .fc-timegrid-axis-frame,
.fc-list-event-time {
  text-transform: capitalize;
}

[data-fc-view]{
  &.active-view{
    color: var(--#{$prefix}btn-hover-color);
    background-color: var(--#{$prefix}btn-hover-bg);
  }
}