/* -------------------------------------------------------------------------- */
/*                                    Text                                    */
/* -------------------------------------------------------------------------- */

.text-smallcaps {
  font-variant: small-caps;
}
.text-superscript {
  vertical-align: super;
}
.text-word-break {
  word-break: break-word;
}



/*-----------------------------------------------
|   Error Page
-----------------------------------------------*/
.fs-error {
  font-size: 7rem;
  @include media-breakpoint-up(sm) {
    font-size: 10rem;
  }
}

/*-----------------------------------------------
|   Text alignment
-----------------------------------------------*/
.text-justify {
  text-align: justify !important;
}
