.dot-typing {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  50% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px -10px 0 0 #9880ff;
  }

  100% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
}

$dot-width: 10px;
$dot-color: #3b5998;
$speed: 1.5s;

.typing {
  position: relative;

  span {
    // content: '';
    display: inline-block;
    animation: blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    // position: absolute;
    // left: 0;
    // top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: $dot-width * 1.5;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: $dot-width * 3;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
